/*
* Global CSS
*/
.yt-single-offer-component[data-v-b73b8ea7] {
  position: relative;
}
.yt-soc-title[data-v-b73b8ea7] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
}
.yt-soc-offer[data-v-b73b8ea7] {
  font-size: 65px;
  font-weight: bold;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-soc-btn[data-v-b73b8ea7] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-ButtonBackRegular);
  width: 100%;
  max-width: 128px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.36px;
  margin-top: 41px;
  text-align: center;
  color: var(--color-ButtonTextRegular)
}
.yt-soc-btn[data-v-b73b8ea7]:hover{
  color: var(--color-ButtonTextHover);
  background-color: var(--color-ButtonBackHover);
}
.yt-soc-inner-content[data-v-b73b8ea7] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.yt-sb-inner-wrap[data-v-b73b8ea7] {
  position: absolute;
  top: 117px;
}
@media (min-width: 1368px) and (max-width: 1900px) {
.yt-soc-title[data-v-b73b8ea7] {
    font-size: 34px;
    line-height: 1.17;
}
.yt-soc-btn[data-v-b73b8ea7] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-sb-inner-wrap[data-v-b73b8ea7]{
    width: 100%;
    max-width: 600px;
}
}
@media (min-width: 1900px) {
.yt-soc-btn[data-v-b73b8ea7] {
    max-width: 180px;
    margin-top: 57px;
    font-size: 18px;
    line-height: 1.11;
}
.yt-soc-title[data-v-b73b8ea7] {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.14;
}
.yt-soc-offer[data-v-b73b8ea7] {
    font-size: 100px;
    font-weight: bold;
    line-height: 1.14;
}
.yt-soc-btn[data-v-b73b8ea7] {
    margin-top: 57px;
}
.yt-sb-inner-wrap[data-v-b73b8ea7] {
    top: 165px;
    width: 100%;
    max-width: 550px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (min-width: 1280px) and (max-width: 1367px){
.yt-sb-inner-wrap[data-v-b73b8ea7]{
    width: 100%;
    max-width: 500px;
}
}
@media (max-width: 1279.92px) {
.yt-sb-inner-wrap[data-v-b73b8ea7] {
    top: 63px;
    width: 100%;
    max-width: 550px;
}
.yt-soc-title[data-v-b73b8ea7] {
    font-size: 20px;
    margin-bottom: 11px !important;
    line-height: 1.15;
}
.yt-soc-offer[data-v-b73b8ea7] {
    font-size: 57px;
    line-height: 1.14;
}
.yt-soc-btn[data-v-b73b8ea7] {
    font-size: 16px;
    line-height: 1.13;
    margin-top: 16px !important;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    width: 100%;
    max-width: 150px;
}
.yt-single-offer-component[data-v-b73b8ea7] {
    margin: 20px 0 !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-sb-inner-wrap[data-v-b73b8ea7] {
    top: 24px;
}
.yt-soc-title[data-v-b73b8ea7] {
    font-size: 20px;
    line-height: 1.14;
}
.yt-soc-offer[data-v-b73b8ea7] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-soc-btn[data-v-b73b8ea7] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px;
}
}
@media (max-width: 576px) {
.yt-single-offer-component[data-v-b73b8ea7] {
    background-size: cover;
    min-height: 186px;
    background-position: center top;
    background-repeat: no-repeat;
}
}
