.radius-10[data-v-09b43890] {
  border-radius: 10px;
}
.trans-fl-pg-mb-30[data-v-09b43890] {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80[data-v-09b43890] {
  margin-bottom: 80px;
}
.trans-fl-ttl[data-v-09b43890] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white);
}
.trans-fl-wrap[data-v-09b43890] {
  margin-bottom: 50px;
  margin-top: 50px;
}
.trans-fl-text[data-v-09b43890] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-white);
}
.trans-fl-btn[data-v-09b43890] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.trans-fl-cancel-btn[data-v-09b43890] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-white);
  box-shadow: none !important;
}
.yt-transaction-cl-icn[data-v-09b43890] {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap[data-v-09b43890] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-orange) !important;
}
@media (min-width: 1280px) {
.trans-fl-pg-inner-wrap[data-v-09b43890] {
    margin-top: 60px !important;
    margin-bottom: 60px;
}
}
@media (min-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-09b43890] {
    margin-top: 80px !important;
    margin-bottom: 80px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.trans-fl-pg-inner-wrap[data-v-09b43890] {
    margin-top: 20px !important;
    margin-bottom: 20px;
}
.trans-fl-wrap[data-v-09b43890] {
    margin-top: 75px;
    margin-bottom: 40px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.trans-fl-pg-inner-wrap[data-v-09b43890] {
    margin-top: 15px !important;
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
}
.trans-fl-btn[data-v-09b43890] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
.cart-pg-empty-main-wrap[data-v-09b43890] {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.trans-fl-cancel-btn[data-v-09b43890] {
    font-size: 12px;
    line-height: 1.17;
}
.trans-fl-ttl[data-v-09b43890] {
    font-size: 16px;
    line-height: 1.13;
}
.trans-fl-text[data-v-09b43890] {
    font-size: 14px;
    line-height: 1.14;
}
.trans-fl-wrap[data-v-09b43890] {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-09b43890] {
    margin-top: 40px !important;
    margin-bottom: 40px;
}
}
