.radius-10[data-v-4f18912b] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-4f18912b] {
  margin-bottom: 30px;
}
.empty-cartn-ttl[data-v-4f18912b] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.empty-cart-text[data-v-4f18912b] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-4f18912b] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
