.yt-forgot-pw-wrap[data-v-c5fa0a10] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-c5fa0a10] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-c5fa0a10] {
  margin-top: 50px;
}
.yt-fp-top-back-tag-name[data-v-c5fa0a10] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: var(--color-blue);
}
.yt-fp-back-icn[data-v-c5fa0a10] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-c5fa0a10] {
  background-image: url(../images/login-image.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-c5fa0a10] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-c5fa0a10] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-c5fa0a10] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-c5fa0a10] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-c5fa0a10] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-sp-now[data-v-c5fa0a10]:hover{
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.yt-fp-tag-line[data-v-c5fa0a10] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-c5fa0a10] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px;
}
.yt-fp-form[data-v-c5fa0a10] {
  position: relative;
}
.yt-fp-form .form-group[data-v-c5fa0a10] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-c5fa0a10] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-c5fa0a10] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-c5fa0a10] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-c5fa0a10] {
  padding-left: 65px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px var(--color-commonBorder);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-c5fa0a10] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-ButtonBackRegular);
  color: var(--color-ButtonTextRegular);
}
.yt-fp-btn[data-v-c5fa0a10]:hover{
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.yt-fp-skip-btn[data-v-c5fa0a10] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-c5fa0a10] {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-c5fa0a10] {
  font-size: 22px;
  line-height: 1.14;
  color: var(--color-orange);
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 0;
}
.yt-fp-form .error.form-group .form-control[data-v-c5fa0a10] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-c5fa0a10] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-c5fa0a10] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-c5fa0a10] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-c5fa0a10] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-c5fa0a10] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-c5fa0a10],
.success.form-group[data-v-c5fa0a10] {
  margin-bottom: 43px !important;
}
.invalid[data-v-c5fa0a10] {
  color: var(--color-orange);
}
.invalid-input[data-v-c5fa0a10] {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input[data-v-c5fa0a10]:active {
  border: 1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-c5fa0a10] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-c5fa0a10] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-c5fa0a10] {
  color: green;
}
.yt-fp-inner-content.yt-banner-col[data-v-c5fa0a10] {
  width: 45%;
}
.yt-fp-inner-content.yt-form-col[data-v-c5fa0a10] {
  padding-left: 0px;
  width: 100%;
}
.invalid[data-v-c5fa0a10] {
  color: var(--color-orange);
}
.invalid-feedback[data-v-c5fa0a10] {
  font-size: 12px;
  line-height: 1.17;
}
/**/
.yt-etpass-msg[data-v-c5fa0a10] {
  line-height: 1.1;
  font-size: 10px;
  position: absolute;
  bottom: 68px;
}
form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback[data-v-c5fa0a10] {
  bottom: -30px;
}
form.yt-new-pass-form-wrap[data-v-c5fa0a10] {
  position: relative;
}
@media (min-width: 1400px) {
.yt-frgt-row .yt-banner-col[data-v-c5fa0a10],
  .yt-frgt-row .yt-form-col[data-v-c5fa0a10] {
    width: 100%;
}
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    max-width: 600px;
}
.col-xxl-7[data-v-c5fa0a10] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-c5fa0a10] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    max-width: 588px;
    padding: 0;
}
.yt-frgt-row .yt-fp-inner-content[data-v-c5fa0a10] {
    width: 100%;
}
.yt-frgt-row .yt-fp-bg-banner[data-v-c5fa0a10] {
    order: 2;
}
.yt-fp-inner-content.yt-form-col[data-v-c5fa0a10] {
    padding: 32px 30px 30px;
}
.yt-fp-top-back-tag-name[data-v-c5fa0a10] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-fp-tag-line[data-v-c5fa0a10] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-fp-tag-line-2[data-v-c5fa0a10] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-fp-form .form-group .form-control[data-v-c5fa0a10] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group[data-v-c5fa0a10] {
    margin-bottom: 20px;
}
.yt-fp-btn[data-v-c5fa0a10] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-fp-bg-banner[data-v-c5fa0a10] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-fp-oi-tag-small[data-v-c5fa0a10] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-fp-oi-tag-big[data-v-c5fa0a10] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-fp-sp-now[data-v-c5fa0a10] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-fp-bdr[data-v-c5fa0a10] {
    bottom: 55px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-fp-top-back-tag-name[data-v-c5fa0a10] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group.yt-form-err span[data-v-c5fa0a10] {
    bottom: -20px;
}
.yt-new-pass-form-wrap .yt-fp-btn[data-v-c5fa0a10] {
    margin-top: 25px !important;
}
form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-fp-btn[data-v-c5fa0a10] {
    margin-top: 40px !important;
}
form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-etpass-msg[data-v-c5fa0a10] {
    bottom: 65px;
}
form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err[data-v-c5fa0a10]:first-child {
    margin-bottom: 35px !important;
}
form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err:first-child span[data-v-c5fa0a10] {
    top: 95%;
}
.yt-fp-inner-content.yt-form-col[data-v-c5fa0a10] {
    padding: 15px;
}
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    max-width: 335px;
}
.yt-fp-tag-line[data-v-c5fa0a10] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-fp-tag-line-2[data-v-c5fa0a10] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-fp-form .form-group .form-control[data-v-c5fa0a10] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-fp-form[data-v-c5fa0a10] {
    margin-bottom: 15px;
}
.yt-fp-oi-tag-small[data-v-c5fa0a10] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-fp-oi-tag-big[data-v-c5fa0a10] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-fp-bg-banner[data-v-c5fa0a10] {
    min-height: 180px;
}
.yt-otp-sd-wrap[data-v-c5fa0a10] {
    margin-top: 20px !important;
    padding: 0 !important;
}
.yt-fp-btn[data-v-c5fa0a10] {
    margin-top: 0 !important;
}
.yt-fp-skip-btn[data-v-c5fa0a10] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    margin-top: 10px;
    margin-bottom: 10px;
}
.yt-fp-form .form-group.yt-form-err[data-v-c5fa0a10] {
    margin-bottom: 28px !important;
}
.yt-fp-form .invalid.yt-form-err[data-v-c5fa0a10] {
    bottom: -20px;
    font-size: 12px;
    line-height: 1.17;
}
}
@media (max-width: 576px) {
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    max-width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-fp-form .form-group[data-v-c5fa0a10] {
    margin-bottom: 20px !important;
}
.yt-fp-btn[data-v-c5fa0a10] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-forgot-pw-wrap[data-v-c5fa0a10] {
    padding: 26px 40px 34px;
}
.yt-fp-tag-line[data-v-c5fa0a10] {
    margin-bottom: 34px;
    margin-top: 26px;
}
.yt-fp-tag-line-2[data-v-c5fa0a10] {
    margin-top: 0;
    margin-bottom: 30px;
}
.invalid[data-v-c5fa0a10] {
    font-size: 12px;
    line-height: 1.17;
}
.invalid-feedback.yt-invalid-minimum-pass[data-v-c5fa0a10] {
    margin: 0;
    top: 108%;
}
.yt-fp-form[data-v-c5fa0a10] {
    margin-bottom: 20px !important;
}
.invalid-feedback[data-v-c5fa0a10] {
    bottom: -17px;
}
.yt-fp-form .yt-new-pass-form-wrap .form-group.yt-form-err[data-v-c5fa0a10] {
    margin-bottom: 36px !important;
}
.yt-new-pass-form-wrap .yt-fp-btn[data-v-c5fa0a10] {
    margin-top: 35px !important;
}
.yt-fp-form .yt-new-pass-form-wrap .yt-form-confirm-err.form-group[data-v-c5fa0a10] {
    margin-bottom: 50px !important;
}
form.yt-new-pass-form-wrap span.yt-etpass-msg[data-v-c5fa0a10] {
    bottom: 68px;
}
form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback[data-v-c5fa0a10] {
    bottom: -35px;
}
form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group + span[data-v-c5fa0a10] {
    bottom: 83px !important;
}
}
