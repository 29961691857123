/*Quantity CSS*/
.cart-quantity-field .form-group[data-v-4f381dac] {
  position: relative;
  border-radius: 2px;
  border: solid 1px var(--color-commonBorder);
}
.cart-quantity-icn[data-v-4f381dac] {
  position: absolute;
  top: 1px;
  bottom: 1px;
  background: #f6f8fa;
  cursor: pointer;
}
.quantity-icn-left[data-v-4f381dac] {
  left: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.quantity-icn-right[data-v-4f381dac] {
  right: 1px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.cart-quantity-field .form-control[data-v-4f381dac] {
  border-radius: 0;
  box-shadow: none !important;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}
.cart-quantity-field input[data-v-4f381dac]::-webkit-outer-spin-button,
.cart-quantity-field input[data-v-4f381dac]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cart-quantity-field input[type="number"][data-v-4f381dac] {
  -moz-appearance: textfield;
}
.cart-quantity-field[data-v-4f381dac] {
  max-width: 120px;
}
/*Quantity CSS END*/

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.cart-quantity-field[data-v-4f381dac] {
    max-width: 100px;
    margin-left: auto;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cart-quantity-field[data-v-4f381dac] {
    max-width: 88px;
    margin-left: auto;
}
}
