.pp-ca-list-none[data-v-818dcabe] {
  list-style: none;
}
.pp-ca-tag-name[data-v-818dcabe] {
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  color: var(--color-dark-grey);
}
.pp-ca-user-name[data-v-818dcabe] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.pp-ca-listing li[data-v-818dcabe] {
  padding: 30px 0;
  border-bottom: 2px solid var(--color-commonBorder);
}
.pp-ca-listing li[data-v-818dcabe]:last-child {
  border: none;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-818dcabe] {
  padding: 40px 30px 30px;
}
.profile-pg-inner-wrap[data-v-818dcabe] {
  border: 1px solid #e6e6e6;
}
.pp-ca-name[data-v-818dcabe] {
  min-height: 40px;
}
.radius-10[data-v-818dcabe] {
  border-radius: 10px;
}
.profile-pg-mb-30[data-v-818dcabe] {
  margin-bottom: 30px;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-818dcabe]:first-child {
  padding-top: 0;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-818dcabe]:last-child {
  padding-bottom: 0;
}
.pp-no-addr-btn[data-v-818dcabe] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-ButtonBackRegular);
  border-color: var(--color-ButtonBackRegular);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-ButtonTextRegular);
  width: 100%;
  max-width: 200px;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-ca-type img[data-v-818dcabe] {
    width: 38px;
    height: 38px;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-818dcabe] {
    padding: 30px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-818dcabe] {
    padding: 13px 15px 26px;
}
.pp-ca-tag-name[data-v-818dcabe] {
    font-size: 12px;
    line-height: 1.17;
    margin-bottom: 5px !important;
}
.pp-ca-user-name[data-v-818dcabe] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-ca-listing li[data-v-818dcabe] {
    padding: 20px 0;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-818dcabe] {
    padding: 22px 20px;
}
.pp-ca-tag-name[data-v-818dcabe] {
    font-size: 12px;
    line-height: 1.17;
}
.pp-ca-user-name[data-v-818dcabe] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-ca-listing li[data-v-818dcabe] {
    padding: 15px 0;
}
.pp-ca-remove[data-v-818dcabe] {
    opacity: 0.5;
}
}
