/*
* Add Address Modal CSs
*/
.size-chart-title-bar[data-v-44d02ba5] {
  border-color: #dbdbdb;
  border-width: 2px;
}
.size-chart-title-bar span[data-v-44d02ba5] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.body-container[data-v-44d02ba5] {
  max-height: 70vh;
  overflow-y: auto;
}
@media (min-width: 1366px) and (max-width: 1900px) {
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.size-chart-title-bar span[data-v-44d02ba5] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-add-modal-body[data-v-44d02ba5] {
    padding: 30px 40px 20px !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.size-chart-title-bar span[data-v-44d02ba5] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-add-modal-body[data-v-44d02ba5] {
    padding: 20px 15px !important;
}
}
