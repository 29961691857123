/*
* YT Product Slider CSS
*/
.yt-product-fav[data-v-da78775d] {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-product-off[data-v-da78775d] {
  background-color: var(--color-orange);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-product-slider-cpnt[data-v-da78775d] {
  border: 1px solid var(--color-commonBorder);
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 50px;
  transition: all .55s ease;
}
.yt-product-slider-cpnt[data-v-da78775d]:hover {
  box-shadow: 0 10px 50px -12px rgba(171,171,171,.9);
  transform: translateY(-10px);
  transition: all .55s ease;
}
.yt-product-slider-cpnt:hover .yt-product-add-btn[data-v-da78775d]{
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.yt-component-wrapper[data-v-da78775d] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-da78775d] {
  outline: none !important;
  box-shadow: none;
}
.yt-slider-left[data-v-da78775d],
.yt-slider-right[data-v-da78775d] {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: var(--color-blue);
}
.yt-item-slider[data-v-da78775d] {
  position: relative;
  width: 100%;
  margin: 0px 10px;
}
.yt-product-title[data-v-da78775d] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
}
.yt-component-more[data-v-da78775d] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-comonent-top-title[data-v-da78775d] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-td-product-img[data-v-da78775d] {
  cursor: pointer;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
}
.yt-product-sale-price[data-v-da78775d] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-reg-price2[data-v-da78775d] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-decoration: line-through;
}
.yt-product-add-btn[data-v-da78775d] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: var(--color-ButtonTextRegular);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: var(--color-ButtonBackRegular);
  border: none;
}
.yt-product-add-btn[data-v-da78775d]:hover{
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.yt-produstslider-info[data-v-da78775d] {
  padding-bottom: 30px;
}
.yt-top-offer-whl[data-v-da78775d] {
  min-height: 42px;
  position: absolute;
    width: 100%;
    left: 0;
}
.hp-product-slider[data-v-da78775d] {
  margin: 60px 0;
}
@media (min-width: 1900px) {
.yt-product-add-btn[data-v-da78775d] {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}
.yt-item-slider[data-v-da78775d] {
    margin: 0 15px;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-da78775d],
  .yt-slider-right[data-v-da78775d] {
    top: 50%;
    transform: translateY(-50%);
}
.yt-slider-left[data-v-da78775d] {
    left: -45px;
}
.yt-slider-right[data-v-da78775d] {
    right: -45px;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-da78775d],
  .yt-slider-right[data-v-da78775d] {
    top: -56px;
}
.yt-slider-left[data-v-da78775d] {
    right: 43px;
}
.yt-slider-right[data-v-da78775d] {
    right: 0;
}
.yt-comonent-link[data-v-da78775d] {
    padding-right: 97px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-slider-right[data-v-da78775d] {
    right: 20px;
}
.yt-comonent-top-title[data-v-da78775d] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-component-more[data-v-da78775d] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-slider-left[data-v-da78775d],
  .yt-slider-right[data-v-da78775d] {
    font-size: 21px;
    top: -41px;
}
.yt-slider-left[data-v-da78775d] {
    right: 42px;
}
.yt-comonent-link[data-v-da78775d] {
    padding-right: 55px;
}
.yt-product-off[data-v-da78775d] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-sglproduct-fav[data-v-da78775d] {
    font-size: 15px;
}
.yt-product-title[data-v-da78775d] {
    font-size: 14px;
    line-height: 0.71;
    margin-top: 15px !important;
}
.yt-price-wrap[data-v-da78775d] {
    padding: 8px 0px 16px !important;
}
.yt-product-reg-price1[data-v-da78775d],
  .yt-product-reg-price2[data-v-da78775d] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-product-slider-cpnt[data-v-da78775d] {
    padding-bottom: 40px;
}
.yt-top-offer-whl[data-v-da78775d] {
    margin-top: 10px !important;
}
.yt-product-add-btn[data-v-da78775d] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.yt-produstslider-info[data-v-da78775d] {
    padding-bottom: 15px;
}
.yt-component-wrapper.yt-slider-component[data-v-da78775d] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}
section.hp-product-slider[data-v-da78775d] {
    margin: 20px 0 !important;
}
.yt-td-product-img[data-v-da78775d] {
    width: 100%;
    height: 200px;
    max-width: 117px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-td-product-img[data-v-da78775d] {
    width: 100%;
    height: 150px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.yt-product-add-btn[data-v-da78775d] {
    font-size: 13px;
    line-height: 1.15;
}
section.hp-product-slider[data-v-da78775d] {
    position: relative;
}
.yt-component-wrapper.yt-slider-component[data-v-da78775d] {
    padding-top: 52px !important;
}
.yt-produstslider-info[data-v-da78775d] {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 18px;
    z-index: 5;
}
.yt-slider-left[data-v-da78775d],
  .yt-slider-right[data-v-da78775d] {
    top: 17px;
    z-index: 10;
}
.yt-component-more[data-v-da78775d] {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
}
.yt-comonent-top-title[data-v-da78775d] {
    font-size: 17px;
    line-height: 1.12;
}
.yt-slider-left[data-v-da78775d] {
    right: 41px;
}
.yt-comonent-link[data-v-da78775d] {
    padding-right: 49px;
}
.yt-product-title[data-v-da78775d] {
    font-size: 16px;
    line-height: 1.19;
}
.yt-product-sale-price[data-v-da78775d],
  .yt-product-reg-price[data-v-da78775d] {
    font-size: 15px;
    line-height: 1.2;
}
.yt-product-off[data-v-da78775d] {
    min-width: 35px;
}
.yt-item-slider[data-v-da78775d] {
    margin: 0 5px;
}
.yt-top-offer-whl[data-v-da78775d] {
    margin-top: 0 !important;
}
section.hp-product-slider[data-v-da78775d] {
    margin: 10px 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-title[data-v-da78775d] {
    font-size: 18px;
    padding-top: 5px;
}
.yt-product-slider-cpnt .price.yt-price-wrap[data-v-da78775d] {
    margin-bottom: 7px;
}
.yt-product-off[data-v-da78775d] {
    font-size: 16px;
    min-width: 50px;
}
.yt-comonent-top-title[data-v-da78775d] {
    font-size: 26px;
}
}
