.yt-3banner-title[data-v-fa800c5f] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  margin-top: 0;

  color: var(--color-black);
}
.yt-3banner-title span[data-v-fa800c5f] {
  color: var(--color-black);
}
.yt-3banner-sub-title[data-v-fa800c5f] {
  font-size: 22px;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-3banner-sp-btn[data-v-fa800c5f] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-ButtonBackRegular);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: var(--color-ButtonTextRegular);
  width: 100%;
  max-width: 180px;
  text-align: center;
}
.yt-3banner-sp-btn[data-v-fa800c5f]:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}
.yt-3banner-divider[data-v-fa800c5f],
.yt-3banner-big-divider[data-v-fa800c5f] {
  height: 1px;
  background: #dbdbdb;
  width: 100%;
  display: block;
  max-width: 300px;
  margin: 15px 0 20px;
  left: -56px;
  position: relative;
}
.yt-3banner-wrapper[data-v-fa800c5f] {
  position: absolute;
  top: 35px;
  left: 56px;
  max-width: 60%;
}
.yt-top-2[data-v-fa800c5f] {
  top: auto;
  bottom: 50px;
}
.yt-top-2 .yt-3banner-title[data-v-fa800c5f] {
  color: var(--color-black);
  font-size: 50px;
  font-weight: 500;
  line-height: 1.15;
}
.yt-top-2 .yt-3banner-sub-title[data-v-fa800c5f] {
  color: var(--color-black);
  font-size: 22px;
  line-height: 1.14;
}
.yt-3banner-big-title[data-v-fa800c5f] {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.12;
  color: #ffffff;
}
.yt-3banner-big-sub-title[data-v-fa800c5f] {
  font-size: 79px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-3banner-big-divider[data-v-fa800c5f] {
  left: 0;
  right: -51px !important;
  margin-left: auto;
  width: 150%;
}
.yt-3banner-last-wrap[data-v-fa800c5f] {
  position: absolute;
  bottom: 50px;
  right: 51px;
}
.yt-full-width[data-v-fa800c5f] {
  max-width: 100%;
}
.yty-bg-3banner[data-v-fa800c5f] {
  min-height: 250px;
  background-size: cover;
  background-position: 100%;
  position: relative;
}
.yt-first-bottom-banner[data-v-fa800c5f] {
  min-height: 550px;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;
}
.yt-cm-three-col[data-v-fa800c5f] {
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
}
.yt-cm-three-col[data-v-fa800c5f] {
  width: 50%;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-first-bottom-banner[data-v-fa800c5f] {
    min-height: 330px;
}
.yty-bg-3banner[data-v-fa800c5f] {
    min-height: 150px;
}
.yt-3banner-title[data-v-fa800c5f] {
    font-size: 22px;
    line-height: 1.14;
}
.yt-3banner-sub-title[data-v-fa800c5f] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-sp-btn[data-v-fa800c5f] {
    font-size: 16px;
    line-height: 1.13;
    padding: 10px !important;
}
.yt-top-2 .yt-3banner-title[data-v-fa800c5f] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-top-2 .yt-3banner-sub-title[data-v-fa800c5f] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-title[data-v-fa800c5f] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-sub-title[data-v-fa800c5f] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-3banner-wrapper[data-v-fa800c5f] {
    top: 19px;
}
.yt-3banner-divider.yt-first-divider[data-v-fa800c5f] {
    margin: 12px 0;
}
.yt-3banner-divider.yt-second-divider[data-v-fa800c5f] {
    margin: 20px 0 16px;
}
.yt-3banner-divider.yt-third-divider[data-v-fa800c5f] {
    margin: 23px 0 16px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.promo-3-botom-info[data-v-fa800c5f] {
    bottom: 30px;
}
.promo-3-img[data-v-fa800c5f] {
    margin-bottom: 10px !important;
}
.yt-3banner-wrapper[data-v-fa800c5f] {
    max-width: 100%;
}
.yt-3banner-big-title[data-v-fa800c5f] {
    margin-bottom: 10px;
}
.yty-bg-3banner[data-v-fa800c5f] {
    background-position: center;
}
.yt-first-bottom-banner[data-v-fa800c5f] {
    background-position: center;
}
.yt-cm-three-col[data-v-fa800c5f] {
    width: 100%;
}
.yt-first-bottom-banner[data-v-fa800c5f] {
    min-height: 322px;
}
.yt-cm-three-col[data-v-fa800c5f] {
    min-height: 379px;
}
.yt-top-2 .yt-3banner-title[data-v-fa800c5f] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-3banner-sp-btn[data-v-fa800c5f] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px;
}
.yt-3promoo-main-wrapper[data-v-fa800c5f] {
    margin: 0;
}
}
